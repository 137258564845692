import { Button, Result } from "antd";
import React from "react";

const errorConfig = {
  // note: antd5 does not support 401 status. If a 401 status is returned, it will be treated as a 404 status. https://ant.design/components/result
  403: {
    status: "403",
    title: "403",
    subTitle: "Sorry, you don't have access to this page.",
  },
  404: {
    status: "404",
    title: "404",
    subTitle: "Sorry, the page you visited does not exist.",
  },
  500: {
    status: "500",
    title: "500",
    subTitle: "Sorry, something went wrong.",
  },
};


const DynamicErrorComponent = ({error}) => {
  const errorInfo = errorConfig[error] || errorConfig[404];

  return (
    <Result
      status={errorInfo.status}
      title={errorInfo.title}
      subTitle={errorInfo.subTitle}
      extra={<Button type="primary" onClick={() => window.history.back()}>Go Back</Button>}
    />
  );
};

export default DynamicErrorComponent;
