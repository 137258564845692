import { useState } from "react";

import { useQuill } from "react-quilljs";
import "quill/dist/quill.snow.css";

import React from "react";

const CaseMessageEditor = ({ form }) => {
  const [quillValue, setQuillValue] = useState();
  React.useEffect(() => {
    form.setFieldsValue({
      message: quillValue,
    });
  }, [quillValue]);
  const { quill, quillRef, Quill } = useQuill({
    modules: {
      // imageDrop: true,
      magicUrl: true,
      imageCompress: {
        quality: 0.8, // default
        maxWidth: 1000, // default
        maxHeight: 1000, // default
        imageType: "image/jpeg", // default
        debug: false, // default
        suppressErrorLogging: false, // default
      },
    },
  });
  if (Quill && !quill) {
    // For execute this line only once.
    const MagicUrl = require("quill-magic-url").default; // Install with 'yarn add quill-magic-url'
    Quill.register("modules/magicUrl", MagicUrl);
    const ImageCompress = require("quill-image-compress").default;
    Quill.register("modules/imageCompress", ImageCompress);
  }
  React.useEffect(() => {
    if (quill) {
      quill.on("text-change", (delta, oldDelta, source) => {
        setQuillValue(quill.root.innerHTML);
      });
    }
  }, [quill]);

  return (
    <div>
      <div ref={quillRef}></div>
    </div>
  );
};

export default CaseMessageEditor;
