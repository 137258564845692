import React from "react";
import { Layout } from "antd";
import Head from 'next/head'
const LoginLayout = (props) => {
	return (
		<Layout>
			<Head>
				<title>Element Critical - Login</title>
			</Head>
			<Layout.Content
				style={{
					minHeight: "100vh",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					backgroundImage: "url(/home-page-1024x576.jpg)",
				    backgroundSize: "cover"
				}}
			>
				{props.children}
			</Layout.Content>
		</Layout>
	);
};

export default LoginLayout;
