import userlib from "@/lib/userlib";
const checkLocationPermissions = (user, location, category, mode) => {
  // MODES POSSIBLE VALUES
  // const possibleModes = ["create", "view"];
  // CATEGORY POSSIBLE VALUES //
  const possibleCategories = [
    "accesslog",
    "case",
    "contact",
    "contract",
    "cmms",
    "inventory",
    "report",
    "installdeinstall",
    "invoice",
    "remotehandsticket",
    "shipmentticket",
    "accessticket",
    "troubleticket",
    "compliance"
  ];

  // const activeCustomer = user?.customers?.find((cust) => cust.id == user?.activeCustomer);
  // if (!activeCustomer) {
  //   return false;
  // }
  if (userlib.isMasterAdmin(user)) {
    return true;
  }
  if (userlib.isLocationAdmin(user)) {
    return true;
  }
  if (!possibleCategories.includes(category.toString().toLowerCase())) {
    return false;
  }
  const currentLocation = userlib.getLocationById(user, location);
  if (!currentLocation) {
    return false;
  }
  if (category == "invoice") {
    const viewInvoiceAccess =
      currentLocation.viewInvoiceAccess == "" ||
      currentLocation.viewInvoiceAccess == "F" ||
      !currentLocation.viewInvoiceAccess
        ? false
        : true;
    return mode == "view" ? viewInvoiceAccess : false;
  }
  if (category == "accesslog") {
    return true;
    const accessLogAccess =
      currentLocation.accessLogAccess == "" ||
      currentLocation.accessLogAccess == "F" ||
      !currentLocation.accessLogAccess
        ? false
        : true;
    return mode == "view" ? accessLogAccess : false;
  }
  if (category == "contact") {
    // const createContactsAccess =
    //   currentLocation.createContactsAccess == "" ||
    //   currentLocation.createContactsAccess == "F" ||
    //   !currentLocation.createContactsAccess
    //     ? false
    //     : true;
    // const viewContactsAccess =
    //   currentLocation.viewContactsAccess == "" ||
    //   currentLocation.viewContactsAccess == "F" ||
    //   !currentLocation.viewContactsAccess
    //     ? false
    //     : true;
    if (mode == "create") {
      // return createContactsAccess;
      return false;
    } else if (mode == "view") {
      // return createContactsAccess || viewContactsAccess;
      return true;
    }

    return false;
  }
  if (category == "contract") {
    const contractAccess =
      currentLocation.contractAccess == "" || currentLocation.contractAccess == "F" || !currentLocation.contractAccess
        ? false
        : true;
    return mode == "view" ? contractAccess : false;
  }
  if (category == "cmms") {
    const viewCMMSAccess =
      currentLocation.viewCMMSAccess == "" || currentLocation.viewCMMSAccess == "F" || !currentLocation.viewCMMSAccess
        ? false
        : true;
    return mode == "view" ? viewCMMSAccess : false;
  }
  if (category == "inventory") {
    return true;
    const viewInventoryAccess =
      currentLocation.viewInventoryAccess == "" ||
      currentLocation.viewInventoryAccess == "F" ||
      !currentLocation.viewInventoryAccess
        ? false
        : true;
    return mode == "view" ? viewInventoryAccess : false;
  }
  if (category == "report") {
    const viewReportsAccess =
      currentLocation.viewReportsAccess == "" ||
      currentLocation.viewReportsAccess == "F" ||
      !currentLocation.viewReportsAccess
        ? false
        : true;
    return mode == "view" ? viewReportsAccess : false;
  }
  if (category == "installdeinstall") {
    const requestInstallDeinstall =
      currentLocation.requestInstallDeinstall == "" ||
      currentLocation.requestInstallDeinstall == "F" ||
      !currentLocation.requestInstallDeinstall
        ? false
        : true;
    // const viewInstallDeinstallRequests =
    //   currentLocation.viewInstallDeinstallRequests == "" ||
    //   currentLocation.viewInstallDeinstallRequests == "F" ||
    //   !currentLocation.viewInstallDeinstallRequests
    //     ? false
    //     : true;
    if (mode == "create") {
      return requestInstallDeinstall;
    } else if (mode == "view") {
      return true;
    }
    return false;
  }
  if (category == "remotehandsticket") {
    const createAccessRH =
      currentLocation.remoteHandsAccess == "" ||
      currentLocation.remoteHandsAccess == "F" ||
      !currentLocation.remoteHandsAccess
        ? false
        : true;
    return createAccessRH;
  }

  if (category == "shipmentticket") {
    const createAccessShipment =
      currentLocation.shipmentTicketAccess == "" ||
      currentLocation.shipmentTicketAccess == "F" ||
      !currentLocation.shipmentTicketAccess
        ? false
        : true;
    return createAccessShipment;
  }

  if (category == "accessticket") {
    const createAccessTicket =
      currentLocation.accessTicketAccess == "" ||
      currentLocation.accessTicketAccess == "F" ||
      !currentLocation.accessTicketAccess
        ? false
        : true;
    return createAccessTicket;
  }

  if (category == "compliance") {
    const complianceAccess =
      currentLocation.complianceAccess == "" ||
      currentLocation.complianceAccess == "F" ||
      !currentLocation.complianceAccess
        ? false
        : true;
        console.log(currentLocation, 'current location compliance access')
        console.log(complianceAccess, 'compliance access?')
    if (mode == "view") {
      return complianceAccess;
    } else {
      return true;
    }
  }

  if (category == "troubleticket") {
    const createCasesAccess =
      currentLocation.createCasesAccess == "" ||
      currentLocation.createCasesAccess == "F" ||
      !currentLocation.createCasesAccess
        ? false
        : true;
    if (mode == "create") {
      return createCasesAccess;
    } else if (mode == "view") {
      return true;
    }
    return false;
  }

  if (category == "case") {
    const createAccessRH =
      currentLocation.remoteHandsAccess == "" ||
      currentLocation.remoteHandsAccess == "F" ||
      !currentLocation.remoteHandsAccess
        ? false
        : true;
    const createAccessShipment =
      currentLocation.shipmentTicketAccess == "" ||
      currentLocation.shipmentTicketAccess == "F" ||
      !currentLocation.shipmentTicketAccess
        ? false
        : true;
    const createAccessTicket =
      currentLocation.accessTicketAccess == "" ||
      currentLocation.accessTicketAccess == "F" ||
      !currentLocation.accessTicketAccess
        ? false
        : true;
    const createCasesAccess =
      currentLocation.createCasesAccess == "" ||
      currentLocation.createCasesAccess == "F" ||
      !currentLocation.createCasesAccess
        ? false
        : true;
    if (mode == "create") {
      return createCasesAccess || createAccessRH || createAccessShipment || createAccessTicket;
    } else if (mode == "view") {
      return true;
    }

    return false;
  }
  return false;
};

const getAllLocationsAccessibleForPermissions = (user, allLocations, category, mode) => {
  // const activeCustomer = user?.customers?.find((cust) => cust.id == user?.activeCustomer);
  // if (!activeCustomer) {
  //   return [];
  // }
  if (userlib.isMasterAdmin(user)) {
    return allLocations;
  }
  return allLocations?.filter((location) => checkLocationPermissions(user, location.location ? location.location : location.internalid, category, mode));
};

const anyLocationsHaveViewAccessPermissions = (user, category, mode) => {
  // const activeCustomer = user?.customers?.find((cust) => cust.id == user?.activeCustomer);
  // if (!activeCustomer) {
  //   return false;
  // }
  if (userlib.isMasterAdmin(user)) {
    return true;
  }

  return userlib.getAllLocations(user)?.some((loc) => checkLocationPermissions(user, loc.location, category, mode));
};

const canCreateContactsWithPermissions = (user, permissions) => {
  console.log(user, permissions, "user and permissions");
  const isMasterAdmin = userlib.isMasterAdmin(user);
  // const activeCustomer = user?.customers?.find((cust) => cust.id == user?.activeCustomer);
  // if (!activeCustomer) {
  //   return false;
  // }
  if (permissions?.isMasterAdmin && !isMasterAdmin) {
    return false;
  }
  if (isMasterAdmin) {
    return true;
  }
  // console.log(activeCustomer.locations, "active customer loc");
  // If requesting location admin access, check if the logged in user is NOT a location admin.
  if (
    permissions.locations.some((item) => item?.isLocationAdmin) &&
    !permissions.locations
      .filter((item) => item?.isLocationAdmin)
      .every((item) => {
        const matchingUserPermission = userlib.getAllLocations(user)?.find(
          (custLoc) => custLoc.childCompanyId == item.childCompanyId
        );
        return matchingUserPermission?.isLocationAdmin;
      })
  ) {
    return false;
  }
  if (
    permissions?.locations?.every((perm) => {
      const usersMatchingLocation = userlib.getAllLocations(user)?.find((loc) => loc.childCompanyId == perm.childCompanyId);
      if (usersMatchingLocation?.isLocationAdmin) {
        return true;
      }
    })
  ) {
    return true;
  } else {
    return false;
  }
};
export {
  checkLocationPermissions,
  getAllLocationsAccessibleForPermissions,
  anyLocationsHaveViewAccessPermissions,
  canCreateContactsWithPermissions,
};
