import React, { useState, useEffect } from "react";
import { Spin, Table, Button } from "antd";
import userlib from "@/lib/userlib";
import s from "./PDFViewer.module.scss";
import useUser from "@/lib/useUser";
import netsuiteLib from "@/lib/netsuitelib";

const PDFViewer = () => {
  const [watermarkedPDFs, setWatermarkedPDFs] = useState([]);
  const [isLoading, setIsLoading] = useState(false); // Create loading state
  const { user } = useUser();

  useEffect(() => {
    setWatermarkedPDFs([]); // Clear watermarked PDFs when user changes 
  }, [user?.assumedUser]);

  function logComplianceDownload(pdfname) {
    // log the download of the compliance documents
    netsuiteLib.logComplianceDocumentDownload(pdfname);
  };

  async function handleAddWatermark(inputPDFPath, pdfName) {
    const watermarkText = userlib.getActiveCompanyName(user);
    setIsLoading(true); // Set loading state to true when request is made
    try {
      const response = await fetch("/api/addwatermark", {
        method: "POST",
        body: JSON.stringify({ inputPDFPath, watermarkText }),
        headers: {
          "Content-Type": "application/json",
        },
      });
      if (response.ok) {
        const data = await response.json();

        const { watermarkedPDFURL } = data;
        setWatermarkedPDFs((watermarkedPDFs) => [...watermarkedPDFs, { url: watermarkedPDFURL, name: pdfName }]);
      } else {
        console.error("Error adding watermark:", response.status);
      }
    } catch (error) {
      console.error("Error adding watermark:", error);
    }
    setIsLoading(false); // Set loading state to false when response is received
  }

  // bridge letter function - need to create a new PDF from a template literal string to create a bridge letter. We want the dates to be dynamic based on the date this function is called.
  async function bridgeLetter(){
    setIsLoading(true); // Set loading state to true when request is made
    try {
      const response = await fetch("/api/addwatermark", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ createLetter: true }),
      });
      if (response.ok) {
        const data = await response.json();
        const { bridgeLetterPDFURL } = data;
        setWatermarkedPDFs((watermarkedPDFs) => [...watermarkedPDFs, { url: bridgeLetterPDFURL, name: "Element Critical 2023 - Bridge Letter" }]);
      } else {
        console.error("Error creating bridge letter:", response.status);
      }
    } catch (error) {
      console.error("Error creating bridge letter:", error);
    }
    setIsLoading(false); // Set loading state to false when response is received
  }

  const columns = [
    {
      title: 'File Name',
      dataIndex: 'fileName',
      key: 'fileName',
      render: (text, record) => {
        if (record.isBridgeLetter) {
          return (
            <div>
              <p className={s.pdfname}>{text}</p>
              <div className={s.watermarkBlock}>
              <Button className={s.watermarkBtn} onClick={() => bridgeLetter(record.fileName)}>Download Bridge Letter</Button>
              </div>
            </div>
            
          );
        } else {
          return (

            <div>
              <p className={s.pdfname}>{text}</p>
              <div className={s.watermarkBlock}>
                <Button
                  className={s.watermarkBtn}
                  onClick={() => handleAddWatermark(record.pdfUrl, record.fileName)}
                >
                  Watermark This PDF
                </Button>
              </div>
            </div>
          )
        }

      },
    },
  ];

  const dataSource = [
    {
      key: 'ISO27001ConfirmationOfEngagement',
      fileName: 'Element Critical 2023 - ISO 27001 Recertification - Final Report',
      pdfUrl: 'https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-ISO27001-Recertification-Final-Report.pdf',
    },
    {
      key: 'PCIDSSAOC',
      fileName: 'Element Critical 2023 - PCI DSS AOC - Final Report',
      pdfUrl: 'https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-PCI-DSS-AOC-FinalReport.pdf',
    },
    {
      key: 'Type1HIPAAFinalReport',
      fileName: 'Element Critical 2023 - Type 1 HIPAA - Final Report',
      pdfUrl: 'https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Type1-HIPAA-FinalReport.pdf',
    },
    {
      key: 'Type2SOC1ISAE3402',
      fileName: 'Element Critical 2023 - Type 2 SOC 1 & ISAE 3402 - Final Report',
      pdfUrl: 'https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Type2-SOC1-ISAE-3402-Final-Report.pdf',
    },
    {
      key: 'Type2SOC2',
      fileName: 'Element Critical 2023 - Type 2 SOC 2 - Final Report',
      pdfUrl: 'https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Type2-SOC-2-Final-Report.pdf',
    },
    {
      key: 'BridgeLetter',
      fileName: 'Element Critical 2023 - Bridge Letter',
      isBridgeLetter: true,
      pdfUrl: 'https://portal.elementcritical.com/compliance_docs_2023/ElementCritical-2023-Bridge-Letter.pdf',
    },
  ];

  return (
    <div>
      <Table
        columns={columns}
        dataSource={dataSource}
        pagination={false}
        rowKey="key"
        loading={isLoading}
      />

      {/* Watermark Output UI */}
      {watermarkedPDFs.length > 0 && (
        <div id="wm-download-div">
          <h2 style={{ paddingTop: '15px' }}>Download PDF Files</h2>
          {watermarkedPDFs.map((pdf, index) => (
            <div key={index}>
              <a href={pdf.url} download={pdf.name} style={{ fontSize: '16px' }} onClick={() => logComplianceDownload(pdf.name)}>
                {pdf.name}
              </a>
            </div>
          ))}
        </div>
      )}

      {/* Loading icon */}
      {isLoading && <Spin size="medium" style={{ paddingTop: '10px' }} />}
    </div>
  );
};
export default PDFViewer;
