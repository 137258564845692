import React from 'react'
import s from "./Spinner.module.scss";
import { Spin } from 'antd'
const Spinner = () => {
   return (
      <Spin className={s.root} />
   )
}

export default Spinner
