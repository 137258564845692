const events = {

    login: 1000,
    logout: 1001,

    casesView: 2000,
    caseCreate: 2001,
    caseView: 2002,
    caseUpdate: 2003,

    contactsView: 3000,
    conctactCreate: 3001,
    contactView: 3002,
    contactUpdate: 3003,
    contactLocationRevoked: 3004,

    invoicesView: 4000,
    invoiceView: 4001,
    invoiceDownload: 4002,

    contractsView: 5000,
    contractView: 5001,
    cabinetView: 5002,
    powerView: 5003,
    crossConnectView: 5004,

    siteAccessView: 6000,

    complianceView: 7000,
    complianceDocumentDownload: 7001,

    guidesView: 8000,
    guidesDocumentDownload: 8001,
}

export default events;