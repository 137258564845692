export default async function fetchJson(url, options) {
  const response = await fetch(url, {
    credentials: "include",
    ...options,
  });
  // if the server replies, there's always some data in json
  // if there's a network error, it will throw at the previous line
  if (response?.ok || response?.status == 200 || response?.success) {
    return await response.json();
  } else if (!response?.ok || response?.status != 200 || !response?.success) {
    console.log(response, "response");
    if (response?.status != 401 && response?.status != 403) {
      const resp = await response.json();
      const error = new Error(resp.message ?? resp.exceptionMessage);
      error.status = 500;
      error.message = resp.message ? resp.message : resp.exceptionMessage || "An unknown error occurred.";
      throw error;
    } else {
      const error = new Error(response?.statusText);
      error.status = response?.status;
      error.message = response.message ? response.message : response.exceptionMessage || "An unknown error occurred.";
      error.errorCode = response?.errorCode;
      throw error;
    }
  }
}
