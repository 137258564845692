import React from "react";
import s from "./ChatMessage.module.scss";
import cn from "classnames";

const ChatMessage = (props) => {
  const messageId = props.messageId;
  const isMyMessage = props.isMyMessage;
  const message = props.message;
  const author = props.author;
  const date = props.date;
  const latest = props.latest;

  const rootClassName = cn(s.root, {
    [s.my_message_root]: isMyMessage,
    [s.response_root]: !isMyMessage
  });
  return (
    <div id={'case-message-' + messageId} className={rootClassName}>
      <div className="space-between">
        <div className={s.author}>{author}  - {date}</div>
        {latest && <div className={s.latest}>Latest</div>}
      </div>
      <div className={s.bubble}>
        <div className={s.inner} dangerouslySetInnerHTML={{ __html: message }} />
        <div id={'attachments-' + messageId} className={s.attachments}>
        <hr className="solid"></hr>
        <div className="flex">
          <div>Attachments:</div>
        </div>
      </div>
      </div>
    </div>
  );
};

export default ChatMessage;
