import "../styles/globals.scss";
// import LayoutWrapper from "../layouts/Wrapper";
import MainLayout from "../layouts/Layout";
import LoginLayout from "../layouts/Login";
import { SWRConfig } from "swr";
import myFetch from "../lib/fetcher";
import Head from "next/head";
import { pdfjs } from "react-pdf";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// 2. Extend the theme to include custom colors, fonts, etc

function MyApp({ Component, pageProps }) {

  const LayoutWrapper = Component.Layout ? LoginLayout : MainLayout;
  return (
    <SWRConfig
      value={{
        fetcher: myFetch,
        onError: (err) => {
          console.error(err, 'error from SWR config');
        },
      }}
    >
      <Head>
        <link rel="icon shortcut" href="/favicon.png" />
        <title>Element Critical Portal</title>
      </Head>
      <LayoutWrapper>
        <Component {...pageProps} />
      </LayoutWrapper>
    </SWRConfig>
  );
}

export default MyApp;
